import React, { useEffect, useState } from "react";
import { Form, Row, Col, Modal, HelpBlock, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import "./AddReservation.scss";
import { useForm } from "react-hook-form";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment-timezone";
import { partySizeSuitableDuration } from "constants/time";
import { getDurations } from "helpers/reservation.helper";
import { filterResourcesForNewReservation } from "helpers/reservation.helper";
import { Business } from "types/business";
import { formatPhone } from "helpers/phone.helper";
import { BuildCusmomizableInputsForm } from "components/Settings/CustomizableInputFiels/BuildCustomizableInputFields";
import { useMediaQuery } from "hooks/useMediaQuery";
import { handleEnterKeyNextField } from "helpers/form.helper";

export type AddReservationForm = {
  name: string;
  partySize?: number;
  phone?: string;
  service?: string;
  resourceName?: string;
  resourceId?: string;
  orderNumber?: string;
  email?: string;
  note?: string;
  date?: Date | string;
  duration?: any;
};

type IForm = AddReservationForm;

type AddReservationModalProps = {
  showReservationModal;
  handleReservationModalClose;
  reservation;
  resources;
  resource;
  // reservations,
  selectedDate;
  handleDateChange;
  resourcesWithReservations;
  timezone;
  business: Business;
};

function AddReservationModal(props: AddReservationModalProps) {
  const {
    showReservationModal,
    handleReservationModalClose,
    reservation,
    resources,
    resource,
    // reservations,
    selectedDate,
    handleDateChange,
    resourcesWithReservations,
    business
  } = props;
  const [durations, setDurations] = useState(getDurations(15));
  const [filteredResources, setFilteredResources] = useState(resources);
  const isMobile = useMediaQuery("(max-width: 768px)");

  moment.tz.setDefault(props.timezone);

  const getDefaultPartySize = () => {
    return business?.settings?.defaultPartySize || 1;
  };

  useEffect(() => {
    if (reservation && reservation.data().reservationTime) {
      handleDateChange(moment(reservation.data().reservationTime.toDate()).tz(props.timezone));
    }
  }, [reservation]);

  const { register, handleSubmit, errors } = useForm<IForm>();
  const emptyForm: IForm = {
    name: "",
    date: "",
    phone: "",
    partySize: getDefaultPartySize() || 1,
    service: "",
    duration: "",
    email: "",
    note: ""
  };

  const getCategoryInitialValue = () => {
    if (reservation && reservation.data() && reservation.data().resourceName) {
      return reservation.data().resourceName;
    }
    return resource;
  };

  const [form, setForm] = useState<IForm>({
    name: reservation && reservation.data() ? reservation.data().name : "",
    date: reservation && reservation.data() ? reservation.data().reservationTime.toDate() : "",
    phone: reservation && reservation.data() ? reservation.data().phone : "",
    partySize: reservation && reservation.data() ? reservation.data().partySize : 1,
    service: reservation && reservation.data() ? reservation.data().service : "",
    resourceName: getCategoryInitialValue(),
    duration: reservation && reservation.data() ? "" + reservation.data().duration : "",
    email: reservation && reservation.data() ? "" + reservation.data().email : "",
    note: reservation && reservation.data() ? "" + reservation.data().note : "",
    orderNumber: reservation && reservation.data() ? reservation.data().orderNumber : ""
  });

  const getGreater = (a, b) => {
    return Math.max(Number(a), Number(b));
  };

  useEffect(() => {
    if (reservation && reservation.data() && reservation.data().resourceName) {
      setForm({ ...form, resourceName: reservation.data().resourceName });
    } else if (resource) {
      setForm({ ...form, resourceName: resource });
    }
  }, [resource, reservation]);

  useEffect(() => {
    const serv = resources.find(rs => rs.name === form.resourceName);
    let minDuration;
    if (serv?.minDuration === -1) {
      minDuration = -1;
    } else {
      minDuration = ["Bar", "Restaurant"].includes(business.type)
        ? partySizeSuitableDuration[Number(form.partySize || 1)]
        : serv?.minDuration;
    }

    const durations = getDurations(minDuration);
    setDurations(durations);
  }, [form.partySize, form.resourceName]);

  useEffect(() => {
    const date = moment.isMoment(selectedDate) ? selectedDate.toDate() : selectedDate;
    const selectedResource = resources.find(r => r.name === form.resourceName);
    setFilteredResources(
      filterResourcesForNewReservation(
        props.timezone,
        resourcesWithReservations,
        resources,
        date,
        selectedResource,
        form.duration,
        reservation?.data()
      )
    );
  }, [resources, selectedDate, form.resourceName, form.duration]);

  const changeDate = date => {
    handleDateChange(date.tz(props.timezone));
  };

  const onSubmit = data => {
    handleReservationModalClose({ ...data, phone: formatPhone(data.phone), date: selectedDate });
    setForm(emptyForm);
  };

  const closeModal = () => {
    handleReservationModalClose();
    setForm(emptyForm);
  };

  return (
    <Modal enforceFocus={false} dialogClassName="add-reservation-modal" show={showReservationModal} onHide={closeModal}>
      {isMobile && (
        <Button className="sign-up-modal-wrapper__close-btn" simple onClick={closeModal}>
          <i className="fa fa-times" />
        </Button>
      )}
      <Card
        title="Add Reservation"
        textCenter={true}
        content={
          <Form
            noValidate
            className="add-reservation"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={handleEnterKeyNextField}
          >
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.name && "error") || null}>
                  <ControlLabel>DATE/TIME</ControlLabel>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      variant="inline"
                      autoOk
                      disablePast
                      value={selectedDate}
                      minutesStep={15}
                      className="reservation-datepicker"
                      onChange={changeDate}
                    />
                  </MuiPickersUtilsProvider>
                  {errors.date && errors.date.type === "required" && <HelpBlock>Date/Time is required</HelpBlock>}
                </FormGroup>
              </Col>
            </Row>
            <BuildCusmomizableInputsForm<AddReservationForm>
              form={form}
              register={register}
              errors={errors}
              business={business}
              setForm={setForm}
              type="reservations"
              alwaysNextBtn
            />
            {filteredResources && filteredResources.length ? (
              <Row>
                <Col md={12}>
                  <FormGroup validationState={(!!errors.resourceName && "error") || null}>
                    <ControlLabel>RESOURCE</ControlLabel>
                    <select
                      className="form-control"
                      name="resourceName"
                      ref={register({ required: true })}
                      value={form.resourceName || ""}
                      onChange={e => setForm(prev => ({ ...prev, resourceName: e.target.value }))}
                    >
                      <option value="">Select Resource</option>
                      {filteredResources.map((resource, index) => {
                        return (
                          <option key={index} value={resource.name}>
                            {resource.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.resourceName && <HelpBlock>Resource is required</HelpBlock>}
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.duration && "error") || null}>
                  <ControlLabel>DURATION</ControlLabel>
                  <select
                    className="form-control"
                    name="duration"
                    ref={register({ required: true })}
                    value={form.duration || ""}
                    onChange={e => setForm(prev => ({ ...prev, duration: e.target.value }))}
                  >
                    <option value="">Select Duration</option>
                    {durations.map((duration, index) => {
                      return (
                        <option key={index} value={duration.value}>
                          {duration.label}
                        </option>
                      );
                    })}
                  </select>
                  {errors.duration && <HelpBlock>Duration is required</HelpBlock>}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="add-reservation__actions">
                  <Button
                    onClick={closeModal}
                    className="add-reservation__cancel"
                    bsStyle="info"
                    pullRight
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button bsStyle="info" pullRight fill type="submit">
                    {reservation && reservation.data() ? "Save Reservation" : "Add Reservation"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      ></Card>
    </Modal>
  );
}

export default AddReservationModal;
