import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, ControlLabel, FormGroup, Row, Modal, HelpBlock } from "react-bootstrap";
import SettingsInput from "./SettingsInput";
import { useForm } from "react-hook-form";
import { environment } from "configs/environment";
import WaitlistSettingsHour from "./WaitlistSettingsHour";
import "./ReservationsSettings.scss";
import { week } from "../../constants/time";
import { updateBusinessSettings, upgradePremiumToPro } from "../../services/profile/profile.service";
import { sendHelpEmail } from "services/help/help.service";
import toastr from "toastr";
import SettingsWrapper from "../../common/ScrollableWrapper/SettingsWrapper";
import Button from "../CustomButton/CustomButton";
import RegularTable from "../../views/RegularTable";
import { ACTIONS, HEADERS } from "../../constants/tables/resources-settings-table";
import cx from "classnames";
import {
  getBusinessResources,
  updateBusinessResource,
  addBusinessResource,
  deleteBusinessResource,
  updateBussinessReservationsType
} from "../../services/businesses/businesses.service";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteMessage } from "../../constants/messages";
import AddResource from "../AddResource/AddResource";
import { RESERVATIONS_TYPES } from "constants/reservation";
import { BeatLoader } from "react-spinners";
import SquareReservationSetup from "../../components/Square/SquareReservationSetup";
import SquareHeader from "components/Square/SquareHeader";
import SquareAppearanceEditModal from "components/Square/SquareAppearanceEditModal";
import { PREMIUM_TO_PRO, SNIPPET_TYPES } from "../../constants/square";
import { getSquareInfo } from "services/square/square.service";
import { useSelector } from "react-redux";
import { createSnippet } from "services/square/square.service";
import WebsiteInstructionsModal from "components/WebsiteInstructionsModal/WebsiteInstructionsModal";
import { STRIPE_UPGRADE_ERROR } from "./SquareSnippetsSettings";
import StripeUpgradeErrorContent from "./StripeUpgradeErrorContent";
import UpgradeSquare from "components/Upgrade/UpgradeSquare";
import { DateTime } from "luxon";
import SpecificDateCalendar from "components/SpecificDates/SpecificDateCalendar";
import AddLocationForGoogleModal from "components/AddLocationForGoogleModal/AddLocationForGoogleModal";
import CustomButton from "../CustomButton/CustomButton";
import { useMediaQuery } from "hooks/useMediaQuery";

const ReservationSettings = ({ settings, business, user, plan, location, ...props }) => {
  const { register, handleSubmit, errors, getValues } = useForm();
  const history = useHistory();
  const [form, setForm] = useState({
    enableReservations: false,
    selfBooking: false,
    reserveWithGoogle: false,
    hours: []
  });

  let params = new URLSearchParams(location.search);
  let startWithPurchase = false;
  let startWithResourceSetup = false;
  if (params.get("step") === "activate") {
    startWithPurchase = true;
  } else if (params.get("step") === "paymentSuccess") {
    startWithResourceSetup = true;
  }

  const [bookLink, setBookLink] = useState("");
  const [selectLocationLink, setSelectLocationLink] = useState("");
  const [bookLinkCopied, setBookLinkCopied] = useState(false);
  const [selectLinkCopied, setSelectLinkCopied] = useState(false);
  const [setupResourcesModal, setSetupResourcesModal] = useState(startWithResourceSetup);
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    type: null
  });
  const [resources, setResources] = useState([]);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [showSpecificDatesModal, setShowSpecificDatesModal] = useState(false);
  const [customSettingsUpdatedMessage, setCustomSettingsUpdatedMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resourcesLoading, setResourcesLoading] = useState(false);
  const [maxResourcesCount, setMaxResourcesCount] = useState(null);
  const [shouldSubmitResources, setShouldSubmitResources] = useState(false);
  const [onlineReservations, setOnlineReservationsModal] = useState(false);
  const [isOnlineReservationsModalFromGoogle, setIsOnlineReservationsModalFromGoogle] = useState(false);
  const [editSnippetModalConfig, setEditSnippetModalConfig] = useState(null);
  const [snippetContent, setSnippetContent] = useState(null);
  const [isShowWebsiteInstructionsModal, setIsShowWebsiteInstructionsModal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(startWithPurchase);
  const [upgradeErrorModal, setUpgradeErrorModal] = useState(false);
  const [showGoogleLocationModal, setShowGoogleLocationModal] = useState({ show: false, type: null });
  const { account } = useSelector(state => state.account || {});
  const deleteResourceMessage = deleteMessage("resource");
  const squareSelector = useSelector(state => state.square);
  const { squareInfo } = squareSelector;
  const [squareLoading, setSquareLoading] = useState(true);
  const [coverLimitOptions, setCoverLimitOptions] = useState([]);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    getSquareInfo().then(() => {
      setSquareLoading(false);
    });
  }, []);
  useEffect(() => {
    if (settings) {
      setForm({
        enableReservations: settings?.enableReservations,
        selfBooking: settings?.selfBooking,
        reserveWithGoogle: settings?.reserveWithGoogle,
        leadTimeValue: settings?.leadTimeValue || 30,
        leadTimeUnits: settings?.leadTimeUnits || "minutes",
        scheduleWindowValue: settings?.scheduleWindowValue || 30,
        scheduleWindowUnits: settings?.scheduleWindowUnits || "days",
        coverFlowLimit: settings?.coverFlowLimit || "",
        hours: settings?.reservations?.hours || []
      });

      let coverLimits = [{ label: "No Limit", value: "" }];
      for (let i = 1; i < 100; i++) {
        coverLimits.push({ value: `${i}`, label: `${i}` });
      }
      setCoverLimitOptions(coverLimits);
      setBookLink(`${environment.waitlistUrl}book/${business.businessId}`);
      setSelectLocationLink(`${environment.waitlistUrl}select/${account.id}/book`);
    }
    // eslint-disable-next-line
  }, [business.businessId]);

  const hideInfoAlert = () => {
    setShowDeleteMessage(false);
  };

  const fetchSquareResources = async (withLoading = true) => {
    setLoading(withLoading);
    try {
      if (!business.businessId) throw new Error("Business id is required");
      const reslt = await getBusinessResources(business.businessId);
      if (!reslt || !reslt.docs) throw new Error("Something went wrong, when fetch resources");
      const resorcs = reslt?.docs
        .map(r => r.data())
        .filter(r => r.reservationsType === "reservations")
        .sort((a, b) => a.maxPartySize - b.maxPartySize);
      const maxRsrcCount = Math.max(...resorcs.map(r => r.maxPartySize), 0) || 0;
      if (maxRsrcCount) {
        setResources(resorcs);
        setMaxResourcesCount(maxRsrcCount);
      } else {
        setResources(null);
        setMaxResourcesCount(null);
      }
    } catch (e) {
      console.error(e);
      setResources(null);
      setMaxResourcesCount(null);
    }
    setLoading(false);
  };

  async function loadBusinessResources() {
    if (business.businessId) {
      const resources = await getBusinessResources(business.businessId);
      if (resources && resources.docs.length) {
        const mappedResources = resources.docs
          .map(br => ({
            ...br.data(),
            id: br.id
          }))
          .filter(r => !r.reservationsType || r.reservationsType === "appointments");
        setResources(mappedResources);
      } else {
        setResources([]);
      }
    }
  }

  useEffect(() => {
    setResources(null);
    if (isNotTL() && isRestaurantReservations(business)) {
      fetchSquareResources();
      return;
    }
    loadBusinessResources();
    // eslint-disable-next-line
  }, [business.businessId, form.selfBooking]);

  const onAddResource = () => {
    setShowResourceModal(true);
  };

  const getReservationsType = business => {
    if (["bar", "restaurant"].includes(business?.type.toLowerCase())) {
      return RESERVATIONS_TYPES.RESERVATIONS;
    }
    return RESERVATIONS_TYPES.APPOINTMENTS;
  };

  const onSpecificDates = () => {
    setShowSpecificDatesModal(true);
  };

  const onGetResourcesForTable = () => {
    if (resources.length > 0) {
      return resources.map(resource => {
        if (resource.minDuration !== -1) {
          return {
            name: resource.name,
            maxReservations: resource.maxReservations,
            minDuration: `${resource.minDuration} minutes`,
            notificationText: resource.notificationText || ""
          };
        } else {
          return {
            name: resource.name,
            maxReservations: resource.maxReservations,
            minDuration: `All day`,
            notificationText: resource.notificationText || ""
          };
        }
      });
    }
  };

  const onSelectResourceForEdit = resource => {
    setSelectedResource(onGetResource(resource));
    setShowResourceModal(true);
  };

  const onShowDeleteResourceMessage = resource => {
    setSelectedResource(onGetResource(resource));
    setShowDeleteMessage(true);
  };

  const onGetResource = resource => {
    return resources.find(r => r.name === resource.name);
  };

  const onHideDeleteAction = () => {
    return false;
  };

  const onDeleteResource = async () => {
    setLoading(true);
    await deleteBusinessResource(business.businessId, selectedResource.id);
    toastr.error("Resource deleted");
    await loadBusinessResources();
    setLoading(false);
    setShowDeleteMessage(false);
    setSelectedResource(null);
  };

  const onEditResource = async resource => {
    const r = {
      ...resource,
      minDuration: resource.minDuration * 1,
      maxReservations: resource.maxReservations * 1
    };
    if (!r.maxDuration) {
      r.maxDuration = 180;
    }
    setLoading(true);
    if (!selectedResource) {
      await addBusinessResource(business.businessId, r);
    } else {
      await updateBusinessResource(business.businessId, selectedResource.id, r);
    }
    await loadBusinessResources();
    toastr.success("Resource updated");
    setShowResourceModal(false);
    setLoading(false);
    setSelectedResource(null);
  };

  const onCloseModal = () => {
    setSelectedResource(null);
    setShowResourceModal(false);
  };

  const onCloseSpecificDatesModal = () => {
    setShowSpecificDatesModal(false);
  };

  const onCopyLink = () => {
    setBookLinkCopied(true);
    setSelectLinkCopied(false);
    const el = document.createElement("textarea");
    el.value = bookLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toastr.success(`Copied To Clipboard`);
  };

  const onCopySelectLocationLink = () => {
    setSelectLinkCopied(true);
    setBookLinkCopied(false);
    const el = document.createElement("textarea");
    el.value = selectLocationLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toastr.success(`Copied To Clipboard`);
  };

  const onSubmit = async data => {
    await updateBusinessSettings(business.businessId, {
      ...{
        enableReservations: form.enableReservations,
        selfBooking: form.enableReservations ? form.selfBooking : false,
        reserveWithGoogle:
          form.enableReservations && form.reserveWithGoogle !== undefined ? form.reserveWithGoogle : false,
        leadTimeValue: form.leadTimeValue,
        leadTimeUnits: form.leadTimeUnits,
        scheduleWindowUnits: form.scheduleWindowUnits,
        scheduleWindowValue: form.scheduleWindowValue,
        coverFlowLimit: form.coverFlowLimit,
        reservationsType: isNotTL() && form.reservationsType === "reservations" ? "reservations" : "appointments",
        reservations: {
          hours: form.hours
        }
      },
      ...data
    });
    if (isRestaurantReservations(business)) {
      setShouldSubmitResources(true);
    } else {
      toastr.success(`Settings updated`);
    }
  };

  const showOnlineReservationsModal = () => {
    setOnlineReservationsModal(true);
  };

  const hideOnlineReservationModal = () => {
    setOnlineReservationsModal(false);
  };
  const cancelSetupOnlineReservation = () => {
    hideOnlineReservationModal();
    setForm({ ...form, selfBooking: false, reserveWithGoogle: false });
  };

  const toggleChecked = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const isNotTL = () => {
    return account.id !== "xj5spEZSQb2wd0nFRKY6";
  };

  const showOnlineReservation = () => {
    return isRestaurantReservations(business) || !isNotTL();
  };

  const showRwGSwitch = () => {
    return isRestaurantReservations(business) && isNotTL();
  };

  const handleOnlineReservationToggle = event => {
    toggleChecked(event);
    if (event.target.checked && isNotTL()) {
      setForm({ ...form, reserveWithGoogle: true });
      showOnlineReservationsModal();
      setIsOnlineReservationsModalFromGoogle(false);
    }
    if (!event.target.checked) {
      setForm(prev => ({ ...prev, reserveWithGoogle: false }));
    }
  };

  const handleReserveWithGoogleToggle = event => {
    toggleChecked(event);
    if (event.target.checked && isNotTL() && !form.selfBooking) {
      setForm({ ...form, selfBooking: true });
      setIsOnlineReservationsModalFromGoogle(true);
      showOnlineReservationsModal();
    }
  };

  const handleEnableReservationToggle = event => {
    if (!event.target.checked) {
      setForm({ ...form, selfBooking: false, enableReservations: false, reserveWithGoogle: false });
    } else {
      toggleChecked(event);
    }
  };

  const showUpgradeModal = () => {
    setUpgradeModal(true);
  };

  const showPurchaseModal = () => {
    setPurchaseModal(true);
  };

  const notifySupportOfReservationsInterest = async () => {
    hideOnlineReservationModal();
    setForm({ ...form, selfBooking: false, reserveWithGoogle: false });
    await sendHelpEmail("I'm interested in Online Reservations. Please help me upgrade to Waitly Professional.");
    window.open("https://support.apple.com/en-us/HT204939", "_blank");
  };

  const checkIfAddressForGoogleReservationSetted = () => {
    const address = business?.address || {};

    const isAddressForGoogleReservationSetted = Boolean(
      address.address1 &&
        address.state &&
        address.city &&
        address.postal &&
        address.country &&
        address.geo &&
        address.geo?.lat &&
        address.geo?.lng
    );

    return isAddressForGoogleReservationSetted;
  };

  const showSetupAddressModal = () => {
    hideOnlineReservationModal();

    const isAddressForGoogleReservationSetted = checkIfAddressForGoogleReservationSetted();

    if (isAddressForGoogleReservationSetted) {
      showSetupResourcesModel();
      return;
    }

    setShowGoogleLocationModal({ show: true, type: "get-started" });
  };

  const showSetupResourcesModel = () => {
    hideOnlineReservationModal();

    if (plan === "free") {
      showPurchaseModal();
      cancelSetupOnlineReservation();
      return;
    }

    if (plan === "premium") {
      // upgrade flow should be here
      cancelSetupOnlineReservation();
      showUpgradeModal();
      return;
    }

    showSetupResourcesModal();
  };

  const showSetupResourcesModal = () => {
    setForm({ ...form, selfBooking: true });
    setSetupResourcesModal(true);
    updateBussinessReservationsType(business.businessId, RESERVATIONS_TYPES.RESERVATIONS);
  };
  const onCloseSetupResourcesModal = selfBooking => {
    setForm({ ...form, selfBooking: selfBooking || false, reserveWithGoogle: selfBooking || false });
    setSetupResourcesModal(false);
  };

  const onNextSetupResourcesModel = data => {
    onCloseSetupResourcesModal(!data.skipped);
    setResources(data);
    if (!data.skipped) {
      setConfirmationModal({ show: true, type: isOnlineReservationsModalFromGoogle ? "google" : "online" });
    }
  };

  const onValidChange = (day, time) => {
    let hours = [...form.hours];
    if (time.begin) {
      hours[week.indexOf(day)].begin = time.begin;
    }
    if (time.end) {
      hours[week.indexOf(day)].end = time.end;
    }
    if (time.closed !== undefined) {
      hours[week.indexOf(day)].closed = time.closed;
    }
    setForm({ ...form, hours: hours });
  };

  const isRestaurantReservations = business => {
    return getReservationsType(business) === RESERVATIONS_TYPES.RESERVATIONS;
  };

  const showWebsiteInstructionsModal = () => {
    setIsShowWebsiteInstructionsModal(true);
  };

  const onCloseWebsiteInstuctionsModal = () => {
    setIsShowWebsiteInstructionsModal(false);
  };

  const handleAppearanceModalClose = async data => {
    const snippet = data?.snippet;
    if (snippet && squareInfo?.squareOnlineSites?.length) {
      try {
        toastr.success("Snippet create in progress...");
        setEditSnippetModalConfig(null);
        await createSnippet({ snippet });
        toastr.success("Snippet was successfully created");
      } catch (err) {
        toastr.error(err.error || "There was an issue during snippet create, please contact support");
      }
    } else {
      if (snippet?.content) {
        setSnippetContent(snippet.content);
        showWebsiteInstructionsModal();
        setEditSnippetModalConfig(null);
        return;
      }
    }

    setEditSnippetModalConfig(null);
  };

  const validateWindow = () => {
    const values = getValues();
    const sv = {};
    sv[values.leadTimeUnits] = values.leadTimeValue;
    const ev = {};
    ev[values.scheduleWindowUnits] = values.scheduleWindowValue;
    let start = DateTime.now().plus(sv);
    let end = DateTime.now().plus(ev);
    return start < end || "Lead time must be before schedule window.";
  };

  const validateCoverFlowLimit = () => {
    const values = getValues();
    if (parseInt(values.coverFlowLimit, 10) < parseInt(maxResourcesCount, 10)) {
      return `Cover flow limit must be at least ${maxResourcesCount} or no one will be able to book your ${maxResourcesCount} top`;
    } else {
      return true;
    }
  };

  const showUpgradeErrorModal = (code = true) => {
    setUpgradeErrorModal(code);
  };

  const onCloseUpgradeErrorModal = () => {
    setUpgradeErrorModal(false);
  };

  const onCloseUpgradeModal = () => {
    setUpgradeModal(false);
  };

  const onClosePurchaseModal = () => {
    setPurchaseModal(false);
  };

  const onSquareToPremiunUpgrade = async () => {
    const result = await upgradePremiumToPro();
    onCloseUpgradeModal();
    if (result) {
      if (result.upgraded) {
        showSetupResourcesModal();
        return;
      }
      if (result.error) {
        const code = result.code;
        if (code === STRIPE_UPGRADE_ERROR.ORIGIN_IOS || code === STRIPE_UPGRADE_ERROR.WRONG_BILLING_ADDRESS) {
          showUpgradeErrorModal(code);
          return;
        }
        toastr.error(result.error);
        return;
      }
    } else {
      toastr.error("Something went wrong");
    }
  };

  const planHasMultipleLocations = () => {
    return plan === "enterprise" || plan == "pro";
  };

  const showIOSWarning = () => {
    return onlineReservations && account.plan === "premium" && account.origin === "ios";
  };

  const showLetsGetStarted = () => {
    if (account.plan === "premium" && account.origin === "ios") {
      return false;
    } else {
      return onlineReservations;
    }
  };

  const maxPartySizeChanged = size => {
    setMaxResourcesCount(size);
  };

  useEffect(() => {}, [squareLoading]);

  useEffect(() => {}, [loading]);

  useEffect(() => {
    const sparams = new Proxy(params, {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    if (sparams.reserveWithGoogle === "true") {
      return;
    }
    if (form.reserveWithGoogle && form.selfBooking) {
      if (!business) return;
      const address = business?.address || {};

      const isAddressForGoogleReservationSetted = Boolean(
        address.address1 &&
          address.state &&
          address.city &&
          address.postal &&
          address.country &&
          address.geo &&
          address.geo?.lat &&
          address.geo?.lng
      );

      if (!isAddressForGoogleReservationSetted) {
        setShowGoogleLocationModal({ show: true, type: "reserve-with-google" });
      }
    }
  }, [form.reserveWithGoogle]);

  useEffect(() => {
    if (!loading && !squareLoading) {
      const sparams = new Proxy(params, {
        get: (searchParams, prop) => searchParams.get(prop)
      });
      if (sparams.reserveWithGoogle === "true") {
        if (form.reserveWithGoogle) {
          return;
        }
        const isAddressForGoogleReservationSetted = checkIfAddressForGoogleReservationSetted();

        if (isAddressForGoogleReservationSetted && form.selfBooking) {
          setForm({ ...form, reserveWithGoogle: true });
          const saveBtn = document.getElementById("reservations-settings-save-btn");
          saveBtn.click();
          setCustomSettingsUpdatedMessage({
            content:
              "Reserve with Google is now turned on. It may take up to 48 hours for the change to be reflected on Google.",
            header: "Congratulations!"
          });
          return;
        }
        if (form.selfBooking) {
          setForm({ ...form, reserveWithGoogle: true });
          return;
        }
        setForm({ ...form, selfBooking: true, reserveWithGoogle: true });
        showOnlineReservationsModal();
        setIsOnlineReservationsModalFromGoogle(true);
      }
    }
  }, [loading, squareLoading]);

  if (loading || squareLoading)
    return (
      <div className="loading-wrapper">
        <BeatLoader margin={2.5} size={20} color={"#026FFF"} />
      </div>
    );

  return (
    <>
      {confirmationModal.show && (
        <Modal
          dialogClassName="online-reservations-confirmation-modal"
          show={confirmationModal.show}
          onHide={() => {
            setConfirmationModal({ show: false, type: null });
          }}
        >
          <CustomButton
            className="online-reservations-confirmation-modal__close-btn"
            simple
            onClick={() => {
              setConfirmationModal({ show: false, type: null });
            }}
          >
            <i className="fa fa-times" />
          </CustomButton>
          {confirmationModal.type === "google" ? (
            <>
              <SquareHeader>Reserve with Google is now turned on!</SquareHeader>
              <div>
                <p className="text-content">
                  It may take google up to 48 hours to reflect this change on your Google my business listing.
                </p>
              </div>
            </>
          ) : (
            <>
              <SquareHeader>Online Reservations is now turned on!</SquareHeader>
              <div>
                <p className="text-content">
                  Use the reservations links in settings or a website widgets to allow customer to book from your
                  website.
                </p>
              </div>
            </>
          )}

          <div className="d-flex justify-content-end gap-2">
            {confirmationModal.type === "google" ? (
              <>
                <Button
                  onClick={() => setConfirmationModal({ show: false, type: null })}
                  bsStyle="info"
                  className="btn-fill"
                  pullRight
                  type="button"
                >
                  OK
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => setConfirmationModal({ show: false, type: null })}
                  bsStyle="info"
                  className=""
                  pullRight
                  type="button"
                >
                  Done
                </Button>
                <Button
                  onClick={() => history.push("/admin/settings?page=widgets")}
                  bsStyle="info"
                  className=" btn-fill"
                  pullRight
                  type="button"
                >
                  Show me Widgets
                </Button>
              </>
            )}
          </div>
        </Modal>
      )}
      {showGoogleLocationModal.show && (
        <AddLocationForGoogleModal
          show={showGoogleLocationModal.show}
          type={showGoogleLocationModal.type}
          onHide={type => {
            if (type === "get-started") {
              cancelSetupOnlineReservation();
              setShowGoogleLocationModal(state => ({ ...state, show: false }));
              return;
            }
            setShowGoogleLocationModal(state => ({ ...state, show: false }));
            setForm({ ...form, reserveWithGoogle: false });
          }}
          onSubmit={type => {
            if (type === "get-started") {
              showSetupResourcesModel();
              setShowGoogleLocationModal(state => ({ ...state, show: false }));
              return;
            }

            setShowGoogleLocationModal(state => ({ ...state, show: false }));
            const saveBtn = document.getElementById("reservations-settings-save-btn");
            saveBtn.click();
          }}
          business={business}
        />
      )}
      {!!upgradeErrorModal && (
        <Modal dialogClassName="square" show={!!upgradeErrorModal} onHide={onCloseUpgradeErrorModal}>
          <div className="square__content">
            <SquareHeader>Online Reservations</SquareHeader>
            <StripeUpgradeErrorContent type={upgradeErrorModal} closeModal={onCloseUpgradeErrorModal} />
          </div>
        </Modal>
      )}
      {showDeleteMessage && (
        <SweetAlert
          showCancel
          title={deleteResourceMessage.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideInfoAlert}
          onConfirm={onDeleteResource}
        >
          {deleteResourceMessage.message}
        </SweetAlert>
      )}
      {showResourceModal && (
        <AddResource
          updateResource={onEditResource}
          resource={selectedResource}
          showResourceModal={showResourceModal}
          closeModal={onCloseModal}
          å
          loading={loading}
        />
      )}
      {showSpecificDatesModal && (
        <SpecificDateCalendar
          showSpecificDateModal={showSpecificDatesModal}
          settings={settings}
          business={business}
          closeModal={onCloseSpecificDatesModal}
        />
      )}
      {showIOSWarning() && (
        <Modal dialogClassName="square" show={onlineReservations} onHide={cancelSetupOnlineReservation}>
          <div className="square__content">
            <div className="step-wrapper">
              <SquareHeader>Online Reservations require Waitly Professional</SquareHeader>
              <div>
                <p className="text-content">
                  Since your Waitly Premium Subscription is through Apple, you'll need to upgrade to Waitly Professional
                  from your Apple Device.
                </p>
              </div>

              <div className="d-flex justify-content-end">
                <Button
                  onClick={cancelSetupOnlineReservation}
                  bsStyle="info"
                  className="square-modal__button square-modal__button--left"
                  pullRight
                  type="button"
                >
                  Skip for now
                </Button>
                <Button
                  onClick={notifySupportOfReservationsInterest}
                  bsStyle="info"
                  className="square-modal__button btn-fill"
                  pullRight
                  type="button"
                >
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showLetsGetStarted() && (
        <Modal dialogClassName="square" show={onlineReservations} onHide={cancelSetupOnlineReservation}>
          <CustomButton
            className="square-modal__close-btn"
            simple
            onClick={() => {
              cancelSetupOnlineReservation();
            }}
          >
            <i className="fa fa-times" />
          </CustomButton>
          <div className="square__content">
            <SquareHeader>
              {isOnlineReservationsModalFromGoogle
                ? "Let's turn on Reserve with Google"
                : "Let's set up online reservations"}
            </SquareHeader>
            <div>
              <p className="text-content">
                {isOnlineReservationsModalFromGoogle
                  ? "You're just a few steps away from taking reservation from Google."
                  : "You're just a few steps away from accepting reservations from your website."}
              </p>
            </div>
            <div
              className={cx({
                "d-flex ": true,
                "mt-1 mb-1 justify-content-center": isMobile,
                "justify-content-end": !isMobile
              })}
            >
              <Button
                onClick={cancelSetupOnlineReservation}
                bsStyle="info"
                className="square-modal__button square-modal__button--left"
                pullRight
                type="button"
              >
                Cancel
              </Button>

              <Button
                onClick={showSetupAddressModal}
                bsStyle="info"
                className="square-modal__button btn-fill"
                pullRight
                type="button"
              >
                Get Started
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {upgradeModal && (
        <Modal dialogClassName="square" show={upgradeModal} onHide={onCloseUpgradeModal}>
          <div className="square__content">
            <SquareHeader>Upgrade account to Waitly Professional</SquareHeader>
            <div className="upgrade-subtitle">Waitly Professional is required to take reservations online.</div>
            <UpgradeSquare
              isProfessional={true}
              exitPoint={"onlineReservations"}
              skipUpgrade={onCloseUpgradeModal}
              onUpgrade={onSquareToPremiunUpgrade}
              upgradeType={PREMIUM_TO_PRO}
            />
          </div>
        </Modal>
      )}
      {purchaseModal && (
        <Modal dialogClassName="square" show={purchaseModal} onHide={onClosePurchaseModal}>
          <div className="square__content">
            <SquareHeader>Activate 30 day free trial of Waitly Professional</SquareHeader>
            <div className="upgrade-subtitle">Waitly Professional is required to take reservations online.</div>
            <UpgradeSquare isProfessional={true} skipUpgrade={onClosePurchaseModal} exitPoint={"onlineReservations"} />
          </div>
        </Modal>
      )}
      {setupResourcesModal && (
        <Modal dialogClassName="square" show={setupResourcesModal} onHide={onCloseSetupResourcesModal}>
          <div className="square__content">
            <div className="step-wrapper">
              {resourcesLoading ? (
                <div className="loader-wrapper">
                  <BeatLoader margin={2.5} size={15} color={"#016BFF"} />
                </div>
              ) : (
                <SquareReservationSetup
                  goToNextStep={onNextSetupResourcesModel}
                  minPartySize={business.settings.reservations.minPartySize}
                  maxResourcesCount={maxResourcesCount}
                  businessId={business.businessId}
                  isLastStep
                  resources={resources}
                  reserveWithGoogle={form.reserveWithGoogle}
                  shoudReturnResorces={true}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
      {editSnippetModalConfig && (
        <SquareAppearanceEditModal
          handleAppearanceModalClose={handleAppearanceModalClose}
          squareInfo={squareInfo}
          config={editSnippetModalConfig}
        />
      )}
      {isShowWebsiteInstructionsModal && (
        <WebsiteInstructionsModal
          show={isShowWebsiteInstructionsModal}
          user={user}
          close={onCloseWebsiteInstuctionsModal}
          snippetContent={snippetContent}
        />
      )}
      <SettingsWrapper
        title={`Reservations ${props.businessName ? " - " + props.businessName : ""}`}
        handleSubmit={handleSubmit(onSubmit)}
        buttonStyles={{ maxWidth: 830, marginTop: 20 }}
        submitBtnDisable={resourcesLoading}
        saveBtnId={"reservations-settings-save-btn"}
      >
        <Row>
          <Col md={6}>
            <SettingsInput
              form={form}
              name={"enableReservations"}
              type={"switch"}
              enabled={true}
              errors={errors}
              value={form.enableReservations}
              label={"Reservations"}
              subtitle={`Ability to book reservations using Waitly.`}
              register={null}
              onChangeValue={handleEnableReservationToggle}
            />
          </Col>
        </Row>
        {showOnlineReservation() && (
          <Row>
            <Col md={6}>
              <SettingsInput
                form={form}
                name={"selfBooking"}
                type={"switch"}
                enabled={form.enableReservations}
                errors={errors}
                value={form.selfBooking}
                label={"Online Reservations"}
                subtitle={`Guests can book online`}
                register={null}
                onChangeValue={handleOnlineReservationToggle}
              />
            </Col>
          </Row>
        )}
        {showRwGSwitch() && (
          <Row>
            <Col md={6}>
              <SettingsInput
                form={form}
                name={"reserveWithGoogle"}
                type={"switch"}
                enabled={form.enableReservations}
                errors={errors}
                value={form.reserveWithGoogle}
                label={"Reserve with Google"}
                subtitle={`Allow guests to book through Google`}
                register={null}
                onChangeValue={handleReserveWithGoogleToggle}
              />
            </Col>
          </Row>
        )}
        {isNotTL() && form.selfBooking && (
          <Row>
            <Col md={12}>
              <FormGroup className="flex settings-form-group" style={{ alignItems: "center" }}>
                <div className="settings-label-wrapper">
                  <ControlLabel style={{ color: !form.selfBooking && "#CAC9C9" }} className="settings-label">
                    Reservations links
                  </ControlLabel>
                  <ControlLabel
                    style={{ color: !form.selfBooking && "#CAC9C9", fontWeight: "600", paddingTop: "15px" }}
                    className="settings-subtitle"
                  >
                    Guest can book a reservation for this location at
                  </ControlLabel>
                  <div className="flex">
                    <div className="settings-waitlist-link-wrapper">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          cursor: !form.selfBooking && "default",
                          pointerEvents: !form.selfBooking && "none",
                          color: !form.selfBooking && "#CAC9C9"
                        }}
                        href={bookLink}
                      >
                        {bookLink}
                      </a>
                    </div>
                    {!bookLinkCopied && (
                      <i
                        onClick={onCopyLink}
                        style={{ color: !form.selfBooking && "#CAC9C9", pointerEvents: !form.selfBooking && "none" }}
                        className="mdi mdi-24px mdi-clipboard-text"
                      />
                    )}
                    {bookLinkCopied && <i style={{ color: "green" }} className="mdi mdi-24px mdi-clipboard-check" />}
                  </div>
                  {planHasMultipleLocations() && (
                    <>
                      <ControlLabel
                        style={{ color: !form.selfBooking && "#CAC9C9", fontWeight: "600", paddingTop: "20px" }}
                        className="settings-subtitle"
                      >
                        Guests can select any of your locations to book a reservation at
                      </ControlLabel>
                      <div className="flex">
                        <div className="settings-waitlist-link-wrapper">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              cursor: !form.selfBooking && "default",
                              pointerEvents: !form.selfBooking && "none",
                              color: !form.selfBooking && "#CAC9C9"
                            }}
                            href={selectLocationLink}
                          >
                            {selectLocationLink}
                          </a>
                        </div>
                        {!selectLinkCopied && (
                          <i
                            onClick={onCopySelectLocationLink}
                            style={{
                              color: !form.selfBooking && "#CAC9C9",
                              pointerEvents: !form.selfBooking && "none"
                            }}
                            className="mdi mdi-24px mdi-clipboard-text"
                          />
                        )}
                        {selectLinkCopied && (
                          <i style={{ color: "green" }} className="mdi mdi-24px mdi-clipboard-check" />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            {isRestaurantReservations(business) && isNotTL() ? (
              <div className="ml-4">
                {loading ? (
                  <div className="loader-wrapper">
                    <BeatLoader margin={2.5} size={15} color={"#016BFF"} />
                  </div>
                ) : (
                  <SquareReservationSetup
                    goToNextStep={() => {
                      if (customSettingsUpdatedMessage) {
                        toastr.success(customSettingsUpdatedMessage.content, customSettingsUpdatedMessage.header);
                        setCustomSettingsUpdatedMessage(null);
                      } else {
                        toastr.success("Settings updated");
                        if (form.reserveWithGoogle) {
                          toastr.success("It may take up to 48 hours for these changes to reflected on Google.");
                        }
                      }
                    }}
                    reserveWithGoogle={form.reserveWithGoogle}
                    maxResourcesCount={maxResourcesCount}
                    resources={resources}
                    isInModal={false}
                    minPartySize={business.settings.reservations.minPartySize}
                    shouldSubmit={shouldSubmitResources}
                    maxPartySizeChanged={maxPartySizeChanged}
                    businessId={business.businessId}
                    setShouldSubmit={setShouldSubmitResources}
                    setLoading={setResourcesLoading}
                  />
                )}
              </div>
            ) : (
              <div style={{ maxWidth: "100%" }} className="ml-2">
                {isRestaurantReservations(business) ? (
                  <FormGroup className="flex settings-form-group" style={{ alignItems: "center", maxWidth: "98%" }}>
                    <div className="settings-label-wrapper">
                      <ControlLabel className="settings-label">Table Capacity</ControlLabel>
                      <ControlLabel className="settings-subtitle">Define tables that can be booked.</ControlLabel>
                    </div>
                    <Button onClick={onAddResource} bsStyle="info" fill pullRight type="button">
                      Add Table Capacity
                    </Button>
                  </FormGroup>
                ) : (
                  <>
                    <FormGroup className="flex settings-form-group" style={{ alignItems: "center", maxWidth: "98%" }}>
                      <div className="settings-label-wrapper">
                        <ControlLabel className="settings-label">Resources</ControlLabel>
                        <ControlLabel className="settings-subtitle">
                          Bookable resources that are limited in supply, such as salon chair, doctor's offices, etc.
                        </ControlLabel>
                      </div>
                      <Button onClick={onAddResource} bsStyle="info" fill pullRight type="button">
                        Add Resource
                      </Button>
                    </FormGroup>
                    {resources?.length > 0 && (
                      <RegularTable
                        rows={onGetResourcesForTable()}
                        editAction={onSelectResourceForEdit}
                        deleteAction={onShowDeleteResourceMessage}
                        tableHeaders={HEADERS}
                        actions={ACTIONS}
                        hideAction={onHideDeleteAction}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </Col>
        </Row>
        {isNotTL() && isRestaurantReservations(business) && (
          <>
            <Row>
              <Col md={12}>
                <FormGroup className="flex settings-form-group" style={{ alignItems: "center" }}>
                  <div className="settings-label-wrapper">
                    <ControlLabel style={{ color: !form.enableReservations && "#CAC9C9" }} className="settings-label">
                      Cover flow limit
                    </ControlLabel>
                    <ControlLabel
                      style={{ color: !form.enableReservations && "#CAC9C9" }}
                      className="settings-subtitle"
                    >
                      Further limit the number of covers that can be booked in a 15 minute window
                    </ControlLabel>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="flex settings-input-wrapper" style={{ marginLeft: "15px", flexDirection: "row" }}>
                  <select
                    name="coverFlowLimit"
                    className="form-control settings-input"
                    defaultValue={form.coverFlowLimit}
                    ref={register({ validate: validateCoverFlowLimit })}
                    style={{ width: "100px", borderColor: "#989898" }}
                  >
                    {coverLimitOptions.map(({ value, label }, index) => (
                      <option value={value} key={"coverLimt" + index}>
                        {label}
                      </option>
                    ))}
                  </select>
                  <span style={{ marginLeft: "10px", color: "#626262", marginTop: "10px" }}>
                    {" "}
                    covers every 15 minutes
                  </span>
                </div>
                {errors && errors["coverFlowLimit"] && errors.coverFlowLimit.type === "validate" && (
                  <HelpBlock style={{ color: "red", marginLeft: "15px" }}>{errors.coverFlowLimit.message}</HelpBlock>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup className="flex settings-form-group" style={{ alignItems: "center" }}>
                  <div className="settings-label-wrapper">
                    <ControlLabel style={{ color: !form.enableReservations && "#CAC9C9" }} className="settings-label">
                      Booking lead time
                    </ControlLabel>
                    <ControlLabel
                      style={{ color: !form.enableReservations && "#CAC9C9" }}
                      className="settings-subtitle"
                    >
                      How much advanced noticed do you require before a guest can book
                    </ControlLabel>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="flex settings-input-wrapper" style={{ marginLeft: "15px", flexDirection: "row" }}>
                  <input
                    name="leadTimeValue"
                    style={{ width: "70px", marginRight: "20px", borderColor: "#979797" }}
                    className="form-control settings-input"
                    type={"number"}
                    ref={register({ required: true, min: 1, max: 180, validate: validateWindow })}
                    defaultValue={form.leadTimeValue}
                  />
                  <select
                    name="leadTimeUnits"
                    className="form-control settings-input"
                    defaultValue={form.leadTimeUnits}
                    ref={register()}
                    style={{ width: "100px", borderColor: "#979797" }}
                  >
                    <option value="minutes">Minutes</option>
                    <option value="hours">Hours</option>
                    <option value="days">Days</option>
                    <option value="months">Months</option>
                  </select>
                </div>
                {errors && errors["leadTimeValue"] && errors.leadTimeValue.type === "validate" && (
                  <HelpBlock style={{ color: "red", marginLeft: "15px" }}>{errors.leadTimeValue.message}</HelpBlock>
                )}
                {errors && errors["leadTimeValue"] && errors.leadTimeValue.type === "required" && (
                  <HelpBlock style={{ color: "red", marginLeft: "15px" }}>Lead time is required.</HelpBlock>
                )}
                {errors && errors["leadTimeValue"] && errors.leadTimeValue.type === "min" && (
                  <HelpBlock style={{ color: "red", marginLeft: "15px" }}>Value must be at least 1.</HelpBlock>
                )}
                {errors && errors["leadTimeValue"] && errors.leadTimeValue.type === "max" && (
                  <HelpBlock style={{ color: "red", marginLeft: "15px" }}>The max value is 180.</HelpBlock>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={11}>
                <FormGroup className="flex settings-form-group">
                  <div className="settings-label-wrapper">
                    <ControlLabel style={{ color: !form.enableReservations && "#CAC9C9" }} className="settings-label">
                      Booking window
                    </ControlLabel>
                    <ControlLabel
                      style={{ color: !form.enableReservations && "#CAC9C9" }}
                      className="settings-subtitle"
                    >
                      How far in advance can guests book
                    </ControlLabel>
                    {form.reserveWithGoogle && (
                      <ControlLabel className="settings-subtitle" style={{ fontStyle: "italic" }}>
                        Note: Booking window for Google should be in range from 7 days to 30 days. Otherwise, it will be
                        set to range bounds.
                      </ControlLabel>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={11}>
                <div className="flex settings-input-wrapper" style={{ marginLeft: "15px", flexDirection: "row" }}>
                  <input
                    name="scheduleWindowValue"
                    style={{ width: "70px", marginRight: "20px", borderColor: "#979797" }}
                    className="form-control settings-input"
                    type={"number"}
                    ref={register({ required: true, max: 180, min: 1 })}
                    defaultValue={form.scheduleWindowValue}
                  />
                  <select
                    name="scheduleWindowUnits"
                    className="form-control settings-input"
                    defaultValue={form.scheduleWindowUnits}
                    ref={register()}
                    style={{ width: "100px", borderColor: "#979797" }}
                  >
                    <option value="minutes">Minutes</option>
                    <option value="hours">Hours</option>
                    <option value="days">Days</option>
                    <option value="months">Months</option>
                  </select>
                </div>
                {errors && errors["scheduleWindowValue"] && errors.scheduleWindowValue.type === "required" && (
                  <HelpBlock style={{ color: "red", marginLeft: "15px" }}>Booking window is required.</HelpBlock>
                )}
                {errors && errors["scheduleWindowValue"] && errors.scheduleWindowValue.type === "min" && (
                  <HelpBlock style={{ color: "red", marginLeft: "15px" }}>Value must be at least 1.</HelpBlock>
                )}
                {errors && errors["scheduleWindowValue"] && errors.scheduleWindowValue.type === "max" && (
                  <HelpBlock style={{ color: "red", marginLeft: "15px" }}>The max value is 180.</HelpBlock>
                )}
              </Col>
            </Row>
          </>
        )}
        <>
          <Row>
            <Col md={11}>
              <FormGroup className="flex settings-form-group" style={{ alignItems: "center" }}>
                <div className="settings-label-wrapper">
                  <ControlLabel style={{ color: !form.enableReservations && "#CAC9C9" }} className="settings-label">
                    Booking hours
                  </ControlLabel>
                  <ControlLabel style={{ color: !form.enableReservations && "#CAC9C9" }} className="settings-subtitle">
                    The hours that can be booked for reservations.
                  </ControlLabel>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            {form.hours.map((hour, index) => (
              <WaitlistSettingsHour
                key={index}
                time={hour}
                value={!hour.closed}
                disabled={!form.enableReservations}
                day={week[index]}
                onValidChange={onValidChange}
              />
            ))}
          </Row>
        </>
        {isNotTL() && isRestaurantReservations(business) && (
          <>
            <Row>
              <Col md={11}>
                <FormGroup className="flex settings-form-group">
                  <div className="settings-label-wrapper">
                    <ControlLabel style={{ color: !form.enableReservations && "#CAC9C9" }} className="settings-label">
                      Holidays and Special Hours
                    </ControlLabel>
                    <ControlLabel
                      style={{ color: !form.enableReservations && "#CAC9C9" }}
                      className="settings-subtitle"
                    >
                      Override hours for specific dates such as holidays.
                    </ControlLabel>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginBottom: "50px" }}>
              <Col md={11}>
                <Button
                  onClick={onSpecificDates}
                  bsStyle="info"
                  style={{ marginLeft: "20px", fontWeight: 600 }}
                  type="button"
                >
                  Set Hours
                </Button>
              </Col>
            </Row>
          </>
        )}
      </SettingsWrapper>
    </>
  );
};

export default ReservationSettings;
